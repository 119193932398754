import { defineComponent, onMounted, getCurrentInstance } from '@vue/composition-api';
import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
export default defineComponent({
    extends: Line,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => { }
        },
        chartOptions: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const internalInstance = getCurrentInstance();
        onMounted(() => {
            if (internalInstance) {
                internalInstance.renderChart(props.chartData, props.chartOptions);
            }
        });
    }
});
