import { defineComponent, watch, ref, computed, onMounted, getCurrentInstance, reactive } from '@vue/composition-api';
import { cloneDeep, isEqual } from 'lodash';
import PricingGraph from '@/pages/vendor/space/pricing/PricingGraph.vue';
import FormIncrementedInput from '@/shared/components/form/FormIncrementedInput.vue';
import PriceInput from '@/uikit/PriceInput.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import SpacePricingForm from '@/pages/vendor/space/pricing/forms/SpacePricingForm';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import toJSON from '@/api/base/toJSON';
import getSpace from '@/api/space/getById';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import InputWithPostfix from '@/components/InputWithPostfix';
import useAsyncPage from '@/shared/composables/useAsyncPage';
import Popover from '@/components/Popover.vue';
export default defineComponent({
    components: {
        PricingGraph,
        PriceInput,
        FormIncrementedInput,
        PageLoadingBar,
        Popover,
        Toggle,
        Option,
        InputWithPostfix
    },
    directives: {
        focusNextOnEnter: {
            inserted(el, _, vnode) {
                el.addEventListener('keyup', ev => {
                    let index = [...vnode.elm.elements].indexOf(ev.target);
                    if (ev.code === 'Enter' && index < vnode.elm.length - 1) {
                        vnode.elm[index + 2].focus(); // Focus next price input (not advertised)
                    }
                });
            }
        }
    },
    props: {
        revert: {
            type: Boolean,
            default: false
        },
        onSave: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const automaticPrices = reactive({
            day: null,
            fullDay: null
        });
        const errors = ref([]);
        const form = ref({});
        const formLoaded = ref({});
        const instance = getCurrentInstance();
        const labels = [...Array(12).keys()].map(x => `${x + 1}h`);
        const loaded = ref(false);
        const renderChart = ref(true);
        const saving = ref(false);
        watch(() => form.value, () => {
            if (formLoaded.value && !isEqual(formLoaded.value, form.value)) {
                emit('updateSpaceDetails', {
                    data: {
                        halfDayPrice: form.value.half_day_price,
                        dayPrice: form.value.day_price,
                        fullDayPrice: form.value.full_day_price,
                        hasDiscount: !!form.value.has_discount,
                        shortDayPrice: form.value.showCustomPrice
                            ? form.value.short_day_price
                            : form.value.half_day_price,
                        discountPercentage: form.value.discount_percentage || null,
                        discountDescription: form.value.discount_description,
                        serviceBreak: form.value.service_break
                    }
                });
            }
            else {
                emit('noChanges');
            }
        }, { deep: true, immediate: true });
        const spacePricing = computed(() => globalThis.$store.getters['$_vendor/spacePricing']);
        const datas = computed(() => {
            const prices = [];
            for (let i = 1; i <= 12; i += 1) {
                let price = 0;
                if (form.value.showCustomPrice && form.value.short_day_price && i < 4) {
                    price = form.value.short_day_price;
                }
                else if ((form.value.full_day_price &&
                    form.value.day_price &&
                    i > 4 &&
                    i < 8) ||
                    (!form.value.full_day_price && form.value.day_price && i > 4)) {
                    price =
                        (form.value.half_day_price +
                            ((form.value.day_price - form.value.half_day_price) / 4) *
                                (i - 4)) /
                            i;
                }
                else if ((form.value.full_day_price && i > 8) ||
                    (!form.value.day_price && form.value.full_day_price && i > 4)) {
                    price =
                        (form.value.day_price +
                            ((form.value.full_day_price - form.value.day_price) / 4) *
                                (i - 8)) /
                            i;
                }
                else if (form.value.day_price && i === 8) {
                    price = form.value.day_price / 8;
                }
                else
                    price = form.value.half_day_price / 4;
                let hoursModifier = i;
                if (i <= 4)
                    hoursModifier = 4;
                if (form.value.showCustomPrice && form.value.short_day_price && i < 4) {
                    if ((form.value.half_day_price / 4) * i >
                        form.value.short_day_price) {
                        price = form.value.half_day_price / 4;
                        hoursModifier = i;
                    }
                    else {
                        price = form.value.short_day_price;
                        hoursModifier = 1;
                    }
                }
                prices.push(Math.round(price * hoursModifier));
            }
            return prices;
        });
        const reset = () => {
            form.value = new SpacePricingForm();
            automaticPrices.day = null;
            automaticPrices.fullDay = null;
        };
        watch(() => props.revert, () => {
            if (props.revert) {
                form.value = cloneDeep(formLoaded.value);
            }
        }, { immediate: true });
        watch(() => spacePricing.value, value => {
            if (value && !root.$route.params.venueId) {
                form.value.loadFromModel(spacePricing.value);
            }
        }, { immediate: true });
        watch(() => props.onSave, () => {
            if (props.onSave) {
                formLoaded.value = cloneDeep(form.value);
            }
        }, { immediate: true });
        const generateChartData = () => {
            return {
                labels: labels,
                datasets: [
                    {
                        label: 'Your total earning, €',
                        fill: true,
                        backgroundColor: 'rgba(215, 243, 255, 0.5)',
                        pointStyle: 'circle',
                        borderColor: '#009FE3',
                        borderWidth: 2,
                        data: datas.value
                    }
                ]
            };
        };
        const generateChartOptions = () => {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: { display: false },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                callback: label => `€${label}`,
                                min: 0,
                                fontSize: 10
                            }
                        }
                    ],
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 10
                            }
                        }
                    ]
                }
            };
        };
        const checkErrors = () => {
            errors.value = [];
            form.value.validate(instance);
            Object.keys(form.value).forEach(key => {
                const err = form.value.errors(key);
                if (err) {
                    errors.value.push(key);
                }
            });
            if (errors.value.length) {
                if (errors.value[0] === 'short_day_price' &&
                    !form.value.showCustomPrice) {
                    return [];
                }
                root.$scrollTo(`#${errors.value[0]}`, 1500, { offset: -100 });
            }
            return errors.value;
        };
        const toggleCustomPrice = (value) => {
            form.value.showCustomPrice = value;
            if (!form.value.showCustomPrice) {
                // When custom price toggle is off, short day price must be set to half day price
                form.value.short_day_price = form.value.half_day_price;
            }
        };
        const canContinue = computed(() => {
            if (saving.value) {
                checkErrors();
                return !errors.value.length;
            }
            return true;
        });
        const { dataLoaded, callAPIAction } = useAsyncPage();
        const setHalfDayPrice = (val) => {
            const price = val || 0;
            automaticPrices.day = (price * 2).toFixed(2);
            automaticPrices.fullDay = (price * 3).toFixed(2);
        };
        const mainAction = async (ev) => {
            if (!ev.x && !ev.y) {
                return;
            }
            saving.value = true;
            checkErrors();
            if (!errors.value.length) {
                globalThis.$store.commit('$_vendor/SET_SPACE_PRICING', form.value);
                globalThis.$router.push({
                    name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.FEATURES
                });
            }
        };
        watch(() => form.value, () => {
            if (!form.value.half_day_price ||
                !form.value.day_price ||
                !form.value.full_day_price)
                return;
            globalThis.$store.commit('$_vendor/SET_SPACE_PRICING', form.value);
        }, { deep: true, immediate: true });
        onMounted(async () => {
            form.value = new SpacePricingForm();
            const { spaceId } = root.$route.params;
            await callAPIAction('$_vendor/loadSpaceFromStorage');
            if (spaceId) {
                const space = await toJSON(getSpace(spaceId));
                Object.keys(form.value).forEach(key => {
                    if (space[key]) {
                        form.value[key] = space[key];
                    }
                });
                if (form.value.short_day_price !== form.value.half_day_price) {
                    form.value.showCustomPrice = true;
                }
                formLoaded.value = cloneDeep(form.value);
            }
            loaded.value = true;
        });
        return {
            automaticPrices,
            canContinue,
            dataLoaded,
            form,
            loaded,
            renderChart,
            checkErrors,
            generateChartData,
            generateChartOptions,
            mainAction,
            reset,
            setHalfDayPrice,
            toggleCustomPrice
        };
    }
});
