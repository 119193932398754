import { defineComponent, onMounted, ref, watch, onUpdated } from '@vue/composition-api';
export default defineComponent({
    props: {
        value: {
            type: [Number, String],
            default: ''
        },
        inline: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        postfix: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        append: {
            type: String,
            default: ''
        },
        appendClass: {
            type: String,
            default: ''
        },
        percentage: {
            type: Boolean,
            default: false
        },
        maxLength: {
            type: Number,
            default: 255
        }
    },
    setup(props, { emit }) {
        const inputValue = ref(props.value);
        const itemsOffset = ref(0);
        const inputBlock = ref(null);
        watch(() => props.value, () => {
            inputValue.value = props.value;
            emit('input', inputValue.value);
        }, { immediate: true });
        watch(() => inputValue.value, () => {
            emit('input', inputValue.value);
        }, { immediate: true });
        const onBlur = (val) => {
            if (props.percentage)
                val.target.value += '%';
        };
        const onFocus = (val) => {
            if (props.percentage &&
                val.target.value[val.target.value.length - 1] === '%') {
                val.target.value = val.target.value.slice(0, -1);
                itemsOffset.value += 10;
            }
        };
        const setItemsOffset = () => {
            const inputEl = inputBlock.value.$el.getElementsByTagName('input')[0];
            const hiddenEl = document.getElementById('hidden-div');
            if (hiddenEl) {
                hiddenEl.innerText = inputEl.value;
                itemsOffset.value = hiddenEl.clientWidth + (props.percentage ? 13 : 0);
            }
        };
        onMounted(() => {
            if (props.postfix)
                setItemsOffset();
            if (props.percentage) {
                if (Number.isInteger(inputValue.value)) {
                    const inputEl = inputBlock.value.$el.getElementsByTagName('input')[0];
                    inputEl.value += '%';
                }
            }
        });
        onUpdated(() => {
            if (!props.postfix)
                itemsOffset.value = 0;
            else
                setItemsOffset();
        });
        return {
            inputBlock,
            inputValue,
            itemsOffset,
            onBlur,
            onFocus,
            setItemsOffset
        };
    }
});
