import Vue from 'vue';
import SpacePricingModel from '@/shared/models/space/SpacePricingModel';
class SpacePricingForm extends SpacePricingModel {
    showCustomPrice;
    discountEnabled;
    constructor(): void {
        super();
        this.showCustomPrice = false;
        this.discountEnabled = false;
    }
    hideAttributes() {
        return ['id'];
    }
    requiredFields() {
        return ['half_day_price'];
    }
    _fullDayPriceValidation(vue, form, errorCode) {
        if (form.full_day_price && form.day_price > form.full_day_price)
            Vue.set(form._errors, 'full_day_price', vue.$t(errorCode));
    }
    _dayPriceValidation(vue, form, errorCode) {
        if (form.day_price && form.half_day_price > form.day_price)
            Vue.set(form._errors, 'day_price', vue.$t(errorCode));
    }
    _shortDayPriceValidation(vue, form, errorCode) {
        if (form.short_day_price && form.short_day_price > form.half_day_price)
            Vue.set(form._errors, 'short_day_price', vue.$t(errorCode));
    }
    validationRules() {
        return {
            half_day_price: [
                {
                    validation: 'required',
                    i18code: 'space.pricing.errors.half_day_price_empty'
                }
            ],
            day_price: [
                {
                    validation: 'required',
                    i18code: 'space.pricing.errors.day_price_empty'
                },
                {
                    validation: this._dayPriceValidation,
                    i18code: 'space.pricing.errors.day_price_validation'
                }
            ],
            full_day_price: [
                {
                    validation: 'required',
                    i18code: 'space.pricing.errors.full_day_price_empty'
                },
                {
                    validation: this._fullDayPriceValidation,
                    i18code: 'space.pricing.errors.full_day_price_validation'
                }
            ],
            short_day_price: [
                {
                    validation: 'requiredIfEqual',
                    field: 'showCustomPrice',
                    value: true,
                    i18code: 'space.pricing.errors.custom_price_empty'
                },
                {
                    validation: this._shortDayPriceValidation,
                    field: 'showCustomPrice',
                    value: true,
                    i18code: 'space.pricing.errors.short_day_price_validation'
                }
            ],
            service_break: [
                {
                    validation: 'oneOfValues',
                    values: [0, 30, 60, 90, 120],
                    i18code: 'space.pricing.errors.service_brake_max_limit'
                }
            ],
            discount_percentage: [
                {
                    validation: 'requiredIfEqual',
                    field: 'has_discount',
                    value: true,
                    i18code: 'space.pricing.errors.discount_percentage_empty'
                }
            ],
            discount_description: [
                {
                    validation: 'requiredIfEqual',
                    field: 'has_discount',
                    value: true,
                    i18code: 'space.pricing.errors.discount_description_empty'
                }
            ]
        };
    }
}
export default SpacePricingForm;
