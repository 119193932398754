import { render, staticRenderFns } from "./SpacePricingPage.vue?vue&type=template&id=857d0190&scoped=true&"
import script from "./SpacePricingPage.vue?vue&type=script&lang=ts&"
export * from "./SpacePricingPage.vue?vue&type=script&lang=ts&"
import style0 from "./SpacePricingPage.vue?vue&type=style&index=0&id=857d0190&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "857d0190",
  null
  
)

export default component.exports