import BaseForm from '@/shared/models/BaseForm';
class SpaceBookingRulesModel extends BaseForm {
    day_price;
    discount_description;
    discount_percentage;
    full_day_price;
    half_day_price;
    has_discount;
    id;
    service_break;
    short_day_price;
    constructor() {
        super();
        this.id = '';
        this.half_day_price = null;
        this.day_price = null;
        this.full_day_price = null;
        this.short_day_price = null;
        this.service_break = 0;
        this.discount_percentage = 0;
        this.discount_description = '';
        this.has_discount = false;
    }
}
export default SpaceBookingRulesModel;
